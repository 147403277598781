import React, { useEffect, memo } from 'react'

import { format } from 'date-fns'

import iconMapMarker from 'assets/images/icon-map-marker.png'
import mallMarker from 'assets/images/mall-marker.png'

import InfoItem from 'components/_new-general-vision/info-item/info-item'
import TableOrderItem from 'components/_store-general-vision/table-order-item'

import { phoneMask } from 'containers/deliverer-info/deliverer-info'

import { formatCEP, formatCurrency } from 'helpers'
import { useAuth, useMapboxView } from 'hooks'
import { addMultipleSymbolPoints } from 'hooks/useMapboxView/helpers'
import { IOrderIntegration } from 'types/order-integration'

import {
    DetailsTabContainer,
    InfoSection,
    SectionTitle,
    Takeout,
    InfoRow,
    MapContainer,
    ScheduleButton,
    EditIcon,
} from './details-tab.styled'

interface Props {
    order: IOrderIntegration | undefined
    onRescheduleOrder(order: IOrderIntegration): void
}

const DetailsTab: React.FC<Props> = memo(({ order, onRescheduleOrder }) => {
    const { mapRef, mapContainerRef } = useMapboxView({})
    const { mall, store } = useAuth()
    const { address, details, header, owner, type } = order

    useEffect(() => {
        setTimeout(() => {
            if (address?.lat && address?.lng) {
                console.log('aaaa', {
                    id: 'map-marker',
                    src: iconMapMarker,
                    coordinates: { lat: address.lat, lng: address.lng },
                })

                addMultipleSymbolPoints(mapRef.current, [
                    {
                        id: 'map-marker',
                        src: iconMapMarker,
                        coordinates: { lat: address.lat, lng: address.lng },
                    },
                    {
                        id: 'mall-marker',
                        src: mallMarker,
                        size: 0.4,
                        coordinates: { lat: mall.address.lat, lng: mall.address.lng },
                    },
                ])
            }
        }, 1000)
    }, [address])

    return (
        <DetailsTabContainer>
            {order.details?.type === 'takeout' && (
                <>
                    <SectionTitle>Entrega</SectionTitle>
                    <Takeout>Retirado no Balcão</Takeout>
                </>
            )}
            <InfoSection>
                <SectionTitle>Informações Sobre o Pedido</SectionTitle>
                <InfoItem label="Lojista" content={store?.name} />
                <InfoRow>
                    <InfoItem label="Canal de venda" content={header.sale_channel} />
                    <InfoItem label="Número do Pedido" content={order.header.reference_id || order.id} />
                </InfoRow>

                {details?.payments?.map((item, i) => (
                    <React.Fragment key={i}>
                        <InfoRow>
                            <InfoItem
                                label="Forma de Pagamento"
                                contentList={[
                                    `${item.name} (${item.type === 'OFFLINE' ? 'Na Entrega' : item.type})`,
                                    item.card_brand,
                                ]}
                            />
                            <InfoItem
                                label={`Valor ${item.pending ? 'Pendente' : 'Pago'}`}
                                content={formatCurrency(item.pending || item.value)}
                            />

                            {!!item.change_for && (
                                <InfoItem label="Troco para" content={formatCurrency(item.change_for)} />
                            )}
                        </InfoRow>
                    </React.Fragment>
                ))}

                <InfoRow>
                    {type !== 'ifood' ? (
                        <InfoItem label="Forma de Pagamento" content={header.payment_code} />
                    ) : (
                        <InfoItem label="" />
                    )}
                    <InfoItem label="Valor Total" content={formatCurrency(header.total_price)} />
                </InfoRow>
            </InfoSection>
            {details?.vouchers?.length > 0 && (
                <InfoSection>
                    <SectionTitle>Vouchers</SectionTitle>
                    {details.vouchers.map((item, i) => (
                        <React.Fragment key={i}>
                            <InfoRow>
                                <InfoItem label="Tipo de Voucher" content={getTypeName(item.target)} />
                                <InfoItem label="Valor do desconto" content={formatCurrency(item.value)} />
                            </InfoRow>
                            {
                                <InfoRow>
                                    <InfoItem
                                        label="Valor  IFOOD"
                                        content={formatCurrency(item.sponsorshipValues[0].value)}
                                    />
                                    <InfoItem
                                        label="Valor  Lojista"
                                        content={formatCurrency(item.sponsorshipValues[1].value)}
                                    />
                                </InfoRow>
                            }
                        </React.Fragment>
                    ))}
                </InfoSection>
            )}
            <InfoSection>
                <SectionTitle>Cliente</SectionTitle>
                <InfoRow>
                    <InfoItem label="Nome do Cliente" content={owner.name} />
                    <InfoItem label="Telefone" content={phoneMask(owner.phone)} />
                </InfoRow>
                <InfoItem label="E-mail do cliente" content={owner.email} />
            </InfoSection>

            <InfoSection>
                <SectionTitle>Endereço</SectionTitle>
                <InfoRow>
                    <InfoItem label="CEP" content={formatCEP(address.zipcode)} />
                    <InfoItem label="Bairro de entrega" content={address.neighborhood as string} />
                </InfoRow>
                <InfoRow>
                    <InfoItem label="Logradouro" content={address.street} />
                    <InfoItem label="Número" content={address.number} />
                </InfoRow>
                <InfoRow>
                    <InfoItem label="Complemento" content={address.complement} />
                    <InfoItem label="Referência" content={address.landmark} />
                </InfoRow>
            </InfoSection>
            <MapContainer ref={mapContainerRef}></MapContainer>

            <InfoSection>
                <SectionTitle>Itens e Observações</SectionTitle>
                {details.items.map((orderItem, index) => (
                    <TableOrderItem key={index} orderItem={orderItem as any} />
                ))}
                <InfoItem label="Observações do pedido" content={order.details.justification} />
            </InfoSection>
            {order.details.delivery_forecast && (
                <InfoSection>
                    <SectionTitle>Agendamento</SectionTitle>

                    <ScheduleButton onClick={() => order && onRescheduleOrder(order)}>
                        {format(order.details.delivery_forecast, 'DD/MM/YYYY - HH:mm')} <EditIcon />
                    </ScheduleButton>
                </InfoSection>
            )}
        </DetailsTabContainer>
    )
})

function getTypeName(type: 'CART' | 'DELIVERY_FEE' | 'ITEM' | 'PROGRESSIVE_DISCOUNT_ITEM') {
    switch (type) {
        case 'CART':
            return 'Carrinho'
        case 'DELIVERY_FEE':
            return 'Frete'
        case 'ITEM':
            return 'Item'
        case 'PROGRESSIVE_DISCOUNT_ITEM':
            return 'Campanha de Produto'
    }
}

export default DetailsTab
