import { useState } from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

import {
    DateInput,
    ErrorMessage,
    EyeIcon,
    HTMLInputProps,
    HTMLTextAreaProps,
    HTMLSelectProps,
    InputItemContainer,
    InputLabel,
    SelectInput,
    TextareaInput,
    TextInput,
    TimeInput,
    ColorInput,
} from './input-item.styles'

interface Props {
    big?: boolean
    errorMessage?: string | null
    inputProps?: HTMLInputProps | HTMLSelectProps | HTMLTextAreaProps | InputMaskProps
    labelText?: string
    options?: React.OptionHTMLAttributes<HTMLOptionElement>[]
    type?:
        | 'select'
        | 'textarea'
        | 'time'
        | 'date'
        | 'password'
        | 'currency'
        | 'number'
        | 'autocomplete'
        | 'color'
        | 'datetime'
        | 'percentage'
    min?: number
    max?: number
    disabled?: boolean
    name?: string
    footer?: any
}

const InputItem: React.FC<Props> = ({
    big,
    errorMessage,
    inputProps,
    labelText,
    options,
    type,
    min,
    max,
    name,
    disabled,
    footer,
}) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    function formatCurrencyValue(value?: string) {
        if (!value) return ''

        const cleanValue = +value.toString().replace(/\D+/g, '')

        const options = { style: 'currency', currency: 'BRL' }
        return new Intl.NumberFormat('pt-br', options).format(Number(cleanValue) / 100)
    }

    function renderInputByType() {
        if ((inputProps as InputMaskProps)?.mask?.length) {
            return (
                <InputMask id={name} name={name} disabled={disabled} {...(inputProps as InputMaskProps)}>
                    <TextInput />
                </InputMask>
            )
        }
        if (!type) {
            return <TextInput name={name} disabled={disabled} {...(inputProps as HTMLInputProps)} type="text" />
        }

        if (type === 'color') {
            return <ColorInput name={name} disabled={disabled} {...(inputProps as HTMLInputProps)} type="color" />
        }

        if (type === 'autocomplete') {
            return (
                <Select
                    id={name}
                    className="autocomplete"
                    options={options}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    name={name}
                    noOptionsMessage={obj => (obj.inputValue = 'Mall sem lojas')}
                    disabled={disabled}
                    styles={colourStyles}
                    isMulti
                    {...(inputProps as HTMLInputProps)}
                />
            )
        }
        if (type === 'number') {
            return (
                <TextInput
                    id={name}
                    name={name}
                    disabled={disabled}
                    min={min}
                    max={max}
                    {...(inputProps as HTMLInputProps)}
                    type="number"
                />
            )
        }

        if (type === 'password') {
            return (
                <TextInput
                    id={name}
                    name={name}
                    {...(inputProps as HTMLInputProps)}
                    type={isPasswordHidden ? 'password' : 'text'}
                />
            )
        }

        return {
            select: (
                <SelectInput {...(inputProps as HTMLSelectProps)}>
                    <option label={inputProps?.placeholder || 'Selecione uma opção'} value={'undefined'}></option>
                    {options?.map((option, index) => (
                        <option key={index} {...option} value={option.value}></option>
                    ))}
                </SelectInput>
            ),
            textarea: <TextareaInput type={type} big={big} {...(inputProps as HTMLTextAreaProps)} />,
            time: <TimeInput type={type} {...(inputProps as HTMLInputProps)} />,
            date: <DateInput type={type} {...(inputProps as HTMLInputProps)} />,
            datetime: <DateInput type={'datetime-local'} {...(inputProps as HTMLInputProps)} />,
            currency: (
                <TextInput
                    {...(inputProps as HTMLInputProps)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const {
                            target: { value },
                        } = event

                        const formattedValue = formatCurrencyValue(value)
                        event.target.value = (Number(formattedValue?.replace(/\D+/g, '')) / 100).toFixed(2)

                        const input = inputProps as HTMLInputProps

                        if (input?.onChange) input.onChange(event as React.ChangeEvent<HTMLInputElement>)

                        return event
                    }}
                    value={formatCurrencyValue(inputProps?.value as string)}
                />
            ),
        }[type]
    }

    return (
        <InputItemContainer className="input-item">
            {labelText && <InputLabel>{labelText}</InputLabel>}

            {renderInputByType()}
            {footer}
            {type === 'password' && <EyeIcon onClick={() => setIsPasswordHidden(!isPasswordHidden)} />}

            <ErrorMessage className="message-error">{errorMessage}</ErrorMessage>
        </InputItemContainer>
    )
}

const colourStyles: any = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: styles => {
        return {
            ...styles,
            color: '#000',
        }
    },
    input: styles => ({ ...styles, border: 'border: 1px solid #bbb', height: 35, minHeight: 35, borderRadius: 4 }),
    placeholder: styles => ({ ...styles, color: '#000', fontSize: 13.33 }),
}

export default InputItem
