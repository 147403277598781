import { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { PrinterContext } from 'contexts/printer-context'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList, SalesChannel } from 'types'
import { IPrinter, IPrinterConfig, IPrinterOrder, IPrinterType } from 'types/printer'

type IForm = {
    print?: {
        production?: {
            auto_print?: boolean
            size?: string
            device?: IPrinter
            sales_channels?: number[]
        }
        dispatch?: {
            auto_print?: boolean
            with_checklist?: boolean
            type?: 'complete' | 'resume'
            device?: IPrinter
            sales_channels?: number[]
        }
    }
}

function usePrinterConfigModalController(ref) {
    const { user, store, mall, setStore } = useAuth()
    const {
        hasConnectionPrinterApp,
        printerList,
        printers,
        setSelectedPrinter,
        checkPrinterAppConnection,
        print,
        getPrinters,
    } = useContext(PrinterContext)

    const { setSuccessModal, setErrorModal, setLoading } = useUI()
    const lateralModalBaseRef = useRef<LateralModalBase>()

    const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([])

    const { values, initialValues, getFieldProps, setFieldValue, setValues, resetForm, handleSubmit } =
        useFormik<IForm>({
            initialValues: {
                print: {
                    production: {
                        auto_print: false,
                    },
                    dispatch: { auto_print: false, with_checklist: false },
                },
            },
            validationSchema: Yup.object().shape({}),
            onSubmit: async (values, { resetForm }) => {
                try {
                    setLoading(true)
                    const { device: productionDevice, ...productionRest } = values.print.production
                    const { device: dispatchDevice, ...dispatchRest } = values.print.dispatch
                    // delete values.print.dispatch.device

                    const { data } = await api.put(`/painel/store/${store.id}`, {
                        configs: {
                            print: {
                                production: productionRest,
                                dispatch: dispatchRest,
                            },
                        },
                    })

                    resetForm({ values })

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Configurações de impressão atualizadas com sucesso!',
                        singleButtonClick() {
                            setStore(data)
                            lateralModalBaseRef.current.close()
                        },
                    })
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setLoading(false)
                }
            },
        })

    useImperativeHandle(
        ref,
        () => ({
            show() {
                lateralModalBaseRef.current?.show()
            },
            close() {
                lateralModalBaseRef.current?.close()
            },
        }),
        []
    )

    const hasPrintConfig = useMemo(() => !!store.configs?.print, [store.configs?.print])

    const orderTemplateTest = useMemo(
        (): IPrinterOrder => ({
            address: {
                location: {
                    lat: -38.5027509,
                    lng: -3.7438823,
                },
                zipcode: '60125151',
                state: 'CE',
                neighborhood: 'Dionísio Torres',
                number: '2095',
                street: 'R. Osvaldo Cruz',
                city: 'Fortaleza',
            },
            customer: `${user.first_name} ${user.last_name}`,
            destiny: {
                floor: { name: 'PISO 1', zone: { name: 'ZONA 01' } },
            },
            items: [
                {
                    cod: 9999,
                    name: 'Combo produto test',
                    price: 20,
                    quantity: 1,
                    obs: 'observação de teste (produto)',
                    subitems: [
                        {
                            cod: '9999',
                            name: 'Acompanhamento test',
                            price: 4.49,
                            quantity: 1,
                            obs: 'Observação de teste (subitem)',
                        },
                    ],
                },
            ],
            payment: 'Pagamento Online',
            reference_id: '9999',
            sale_channel: 'Mercadoo',
            store: {
                logo: store.logo,
                name: store.name,
                slug: store.slug,
            } as IPrinterOrder['store'],
            value: '24.49',
            type: 'takeout',
            created_at: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            observation: 'Observação de teste',
        }),
        [store, user]
    )

    const printerFormattedList = useMemo(() => {
        return printerList?.map(printer => ({
            label: `${printer.name !== '?' ? printer.name : 'Impressora'} - ${printer.ip}`,
            value: printer.ip,
        }))
    }, [printerList])

    const selectedPrinters = useMemo(
        () => ({
            production: values?.print?.production,
            dispatch: values?.print?.dispatch,
        }),
        [values]
    )

    const isDirty = useMemo(() => {
        const dirtyFields = getDirtyValues<IForm>(values, initialValues)
        return !!Object.keys(dirtyFields).length
    }, [values, initialValues])

    const _getPrinters = useCallback(async () => {
        setLoading(true)
        try {
            await getPrinters()
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }
    }, [getPrinters])

    const _getSalesChannels = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<SalesChannel>>('/painel/sales-channels')
            setSalesChannels(data.items)
        } catch (error) {
            console.log({ error })
        }
    }, [])

    const _printerName = useCallback(
        (item: IPrinter) => `${item.name !== '?' ? item.name : 'Impressora'} - ${item.ip}`,
        []
    )

    const _changeAutoPrint = useCallback(
        (field: IPrinterType) => {
            return () => setFieldValue(`print.${field}.auto_print`, !values.print[field].auto_print)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.print]
    )

    const _changeRadioInput = useCallback(
        (field: IPrinterType, value: string) => {
            return () => {
                if (field === 'production') {
                    setFieldValue(`print.${field}.size`, value)
                    return
                }

                setFieldValue(`print.${field}.type`, value)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.print]
    )

    const _changeIsChecklist = useCallback(
        () => setFieldValue(`print.dispatch.with_checklist`, !values.print.dispatch.with_checklist),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.print]
    )

    const _handleSelectPrinter = useCallback(
        (type: IPrinterType) =>
            ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const printer = printerList.find(item => item.ip === value)
                setSelectedPrinter(type, printer)
            },
        [printerList, setSelectedPrinter]
    )

    const _handleRemoveSelectedPrinter = useCallback(
        (type: IPrinterType) => () => {
            setSelectedPrinter(type, null)
        },
        [setSelectedPrinter]
    )

    const _handleSalesChannels = useCallback(
        (field: IPrinterType, channelId: number) => () => {
            const salesChannels = values.print[field]?.sales_channels

            if (salesChannels?.includes(channelId)) {
                setFieldValue(
                    `print.${field}.sales_channels`,
                    salesChannels?.filter(id => id !== channelId)
                )
                return
            }

            setFieldValue(`print.${field}.sales_channels`, salesChannels ? [...salesChannels, channelId] : [channelId])
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.print]
    )

    const _printTest = useCallback(
        (printType: IPrinterType, orderType: IPrinterOrder['type'] = 'delivery', config?: IPrinterConfig) =>
            async () => {
                await print(printType, { ...orderTemplateTest, type: orderType }, config)
            },
        [orderTemplateTest, print]
    )

    useEffect(() => {
        if (store?.configs?.print) {
            setValues({ print: store.configs.print })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store])

    useEffect(() => {
        _getSalesChannels()
    }, [_getSalesChannels])

    useEffect(() => {
        if (printers) {
            resetForm({
                values: {
                    print: {
                        production: {
                            ...store?.configs?.print?.production,
                            device: printers?.production,
                        },
                        dispatch: {
                            ...store?.configs?.print?.dispatch,
                            device: printers?.dispatch,
                        },
                    },
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printers, store])

    return {
        values,
        isDirty,
        printers,
        printerList,
        salesChannels,
        hasPrintConfig,
        selectedPrinters,
        lateralModalBaseRef,
        printerFormattedList,
        hasConnectionPrinterApp,
        getFieldProps,
        _printTest,
        handleSubmit,
        checkPrinterAppConnection,
        _getPrinters,
        _printerName,
        _changeAutoPrint,
        _changeIsChecklist,
        _changeRadioInput,
        _handleSalesChannels,
        _handleSelectPrinter,
        _handleRemoveSelectedPrinter,
    }
}

const getDirtyValues = <T>(values, initialObject: T): any => {
    const data = { ...values }
    const keyValues = Object.keys(data)

    const dirtyValues = keyValues.filter(keyValue => data[keyValue] !== initialObject[keyValue])

    keyValues.forEach(key => {
        if (!dirtyValues.includes(key)) delete data[key]
    })

    return data
}

export { usePrinterConfigModalController }
