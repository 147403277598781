import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ButtonLoadMore from 'components/button-load-more/button-load-more'
import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'
import { Grid } from 'components/grid/grid'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { formatCurrency, getFormattedDateFilters } from 'helpers'
import { useAuth } from 'hooks'
import { api2, marketplaceV1 } from 'services/api'
import { IPagination } from 'types/paginate'
import { IPostpaidSales, IPostpaidSalesOverview } from 'types/postpaid'

import { AttendanceDetailModal } from './components'
import {
    ActionButton,
    ActionsButtonRow,
    Container,
    Content,
    DateFilterActionsContainer,
    FilterContainer,
    Header,
    HeaderTitle,
    PeriodButton,
    Row,
    SalesPanelContainer,
    SalesPanelContent,
    SalesPanelItem,
    SalesPanelItemTitle,
    SalesPanelItemValue,
    SalesPanelTitle,
    SearchIcon,
    StatusText,
} from './postpaid-sales.styled'

const columnStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

type IStatus = 0 | 1

type IFilters = {
    start_date?: string
    end_date?: string
    selectedPeriod?: string
    status: IStatus | 'all' | null
}

type IPaginationFilters = {
    has_more: boolean
    per_page: number
    current_page: number
}

const paginationValues = {
    has_more: false,
    per_page: 40,
    current_page: 1,
}

const PostpaidSalesScreen = memo(_ => {
    const { store, mall } = useAuth()
    const { setErrorModal, setLoading } = useUI()

    const history = useHistory()

    const attendanceDetailModalRef = useRef<AttendanceDetailModal>()

    const [paginationFilters, setPaginationFilters] = useState<IPaginationFilters>(paginationValues)
    const [attendancesOverview, setAttendancesOverview] = useState<IPostpaidSalesOverview>()
    const [attendances, setAttendances] = useState<IPostpaidSales[]>([])

    const { values, getFieldProps, handleSubmit, setValues } = useFormik<IFilters>({
        initialValues: {
            status: 'all',
            selectedPeriod: 'today',
            start_date: format(new Date(), 'YYYY-MM-DD'),
            end_date: format(new Date(), 'YYYY-MM-DD'),
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: async values => {
            setPaginationFilters(paginationValues)
            await Promise.allSettled([_getAttendances(values, paginationValues), _getOverview(values)])
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    const paginationParams = useMemo((): IPaginationFilters => {
        return {
            current_page: paginationFilters.current_page,
            per_page: paginationFilters.per_page,
        } as IPaginationFilters
    }, [paginationFilters.current_page, paginationFilters.per_page])

    const hasPagination = useMemo((): boolean => {
        return Boolean(paginationFilters.has_more)
    }, [paginationFilters.has_more])

    const _showOrderDetails = useCallback(
        (id: number) => () => {
            attendanceDetailModalRef.current.show(id)
        },
        []
    )

    const _getOverview = useCallback(
        async (filters: IFilters) => {
            try {
                const { data } = await marketplaceV1.get<IPostpaidSalesOverview>('/attendances/scoreboard', {
                    params: { ...filters, stores: [store.id] },
                })
                setAttendancesOverview(data)
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Houve um erro ao consultar informações de visão geral',
                })
            }
        },
        [store]
    )

    const _getAttendances = useCallback(
        async (filters: IFilters, paginationFilters?: IPaginationFilters) => {
            const { selectedPeriod, ...rest } = filters
            const { has_more, ...paginationParams } = paginationFilters

            if (rest.status === 'all') {
                delete rest.status
            }

            setLoading(true)
            try {
                const {
                    data: {
                        items,
                        meta: { paginate },
                    },
                } = await marketplaceV1.get<IPagination<IPostpaidSales, IPostpaidSalesOverview>>('/attendances', {
                    params: {
                        stores: [store.id],
                        ...paginationParams,
                        ...(rest ? rest : null),
                    },
                })
                setPaginationFilters(state => ({
                    ...state,
                    has_more: paginate.has_more,
                    current_page: paginate.current_page,
                }))
                setAttendances(state => {
                    if (paginate.current_page > 1) {
                        return [...state, ...items]
                    }
                    return items
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Houve um erro ao listar relatório de atendimentos',
                })
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    const _refreshPagination = useCallback(async () => {
        const { current_page, ...filters } = paginationParams
        await _getAttendances(values, {
            ...filters,
            current_page: current_page + 1,
        })
    }, [_getAttendances, values, paginationParams])

    const _getStatusName = useCallback((value?: boolean) => {
        if (value === null) {
            return 'Pendente'
        }
        return value ? 'Em andamento' : 'Encerrado'
    }, [])

    const _downloadSalesReport = useCallback(async () => {
        const { start_date, end_date } = values

        const { data } = await api2.get(`/report/products`, {
            responseType: 'blob',
            params: {
                store_id: store.id,
                start_date,
                end_date,
                download: 'xlsx',
                prepaid: 0,
            },
        })

        const file = new Blob([data], { type: data.type })
        const downloadUrl = window.URL.createObjectURL(file)
        let periodFileName = ''

        if (start_date === end_date) {
            periodFileName = format(start_date, 'DD/MM/YYYY')
        } else {
            periodFileName = `${format(start_date, 'DD/MM/YYYY')} à ${format(end_date, 'DD/MM/YYYY')}`
        }

        const filename = `${store.name} - Vendas (pós-pago) de ${periodFileName}.xlsx`
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100)
    }, [values, store])

    useEffect(() => {
        _getAttendances(values, paginationParams)
    }, [_getAttendances])

    useEffect(() => {
        _getOverview(values)
    }, [_getOverview])

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        setValues({
            start_date: query.get('start_date') ?? format(new Date(), 'YYYY-MM-DD'),
            end_date: query.get('end_date') ?? format(new Date(), 'YYYY-MM-DD'),
            status: query.get('status') ? (Number(query.get('status')) as IStatus) : 'all',
        })
    }, [location.search])

    useEffect(() => {
        if (!mall.configs?.operation?.types?.includes('postpaid')) {
            history.replace('vendas')
        }
    }, [history, mall])

    return (
        <Container>
            <AttendanceDetailModal ref={attendanceDetailModalRef} />
            <Header>
                <Row>
                    <HeaderTitle>Vendas (Pós pago)</HeaderTitle>
                </Row>
                <FilterContainer>
                    <InputItem labelText="Data inicial" type="date" inputProps={getFieldProps('start_date')} />
                    <InputItem labelText="Data Final" type="date" inputProps={getFieldProps('end_date')} />
                    <InputItem
                        labelText="Status"
                        type="select"
                        options={[
                            { label: 'Todos', value: 'all' },
                            { label: 'Em andamento', value: 1 },
                            { label: 'Encerrado', value: 0 },
                        ]}
                        inputProps={getFieldProps('status')}
                    />
                    <DefaultButton widthFitContainer onClick={() => handleSubmit()}>
                        <SearchIcon />
                    </DefaultButton>
                </FilterContainer>
                <DateFilterActionsContainer>
                    {periodFilters.map((filter, filterIndex) => (
                        <PeriodButton
                            key={filterIndex}
                            outline
                            widthFitContainer
                            isSelected={filter.key === values.selectedPeriod}
                            onClick={filter.onClick}
                        >
                            {filter.label}
                        </PeriodButton>
                    ))}
                </DateFilterActionsContainer>
            </Header>
            <SalesPanelContainer>
                <SalesPanelTitle>Valores Gerais</SalesPanelTitle>
                <SalesPanelContent>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Qtd de Pedidos</SalesPanelItemTitle>
                        <SalesPanelItemValue>{attendancesOverview?.total ?? 0}</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Comissão</SalesPanelItemTitle>
                        <SalesPanelItemValue>{store.configs?.commission ?? 0}%</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Valor Total Bruto</SalesPanelItemTitle>
                        <SalesPanelItemValue>{formatCurrency(attendancesOverview?.total_amount)}</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <DefaultButton
                            simple
                            widthFitContainer
                            outline
                            title="Baixar relatório de vendas"
                            onClick={_downloadSalesReport}
                        />
                    </SalesPanelItem>
                </SalesPanelContent>
            </SalesPanelContainer>
            <Content>
                <FlexTable
                    columns={[
                        { name: 'Comanda', width: '10%', style: columnStyle },
                        { name: 'Cliente', width: '20%', style: columnStyle },
                        { name: 'Data', width: '15%', style: columnStyle },
                        { name: 'Método de Pagamento', width: '15%', style: columnStyle },
                        { name: 'Valor da Venda', width: '15%', style: columnStyle },
                        { name: '', width: '10%', style: columnStyle },
                    ]}
                    list={attendances?.map(item => ({
                        id: (
                            <Grid.Column align="center">
                                {item.command.code}
                                <StatusText isActive={item.status}>{_getStatusName(item.status)}</StatusText>
                            </Grid.Column>
                        ),
                        client: item.client.name,
                        data: (
                            <Grid.Column>
                                <Text text={format(item.created_at, '[Abertura]: DD/MM/YYYY [às] HH:mm')} />
                                {item.finished_at && (
                                    <Text text={format(item.finished_at, '[Fechamento]: DD/MM/YYYY [às] HH:mm')} />
                                )}
                            </Grid.Column>
                        ),
                        payment_method: item?.payment ? (
                            <Grid.Column justify="center" align="center">
                                {item?.payment.type}
                                <Text text={item.payment.gateway_id} dimensions="caption" />
                            </Grid.Column>
                        ) : (
                            'Pendente'
                        ),
                        saleValue: formatCurrency(item.subtotal),
                        actions: (
                            <ActionsButtonRow>
                                <ActionButton onClick={_showOrderDetails(item.id)}>Ver Mais</ActionButton>
                            </ActionsButtonRow>
                        ),
                    }))}
                />
            </Content>
            <ButtonLoadMore loading={false} visible={hasPagination} onClick={_refreshPagination} />
        </Container>
    )
})

export { PostpaidSalesScreen }
