import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MallsListingTableRow = ({ id, name, address, status, deleteClick, editClick, configClick }) => {
    return (
        <div className="malls-table-row-item">
            <div className="malls-table-row-content id">{id}</div>
            <div className="malls-table-row-content label">{name}</div>
            <div className="malls-table-row-content address">
                {address?.city.state.uf} - {address?.city.name} <br />
                {address?.neighborhood.name}
            </div>
            <div className="malls-table-row-content status">{status}</div>
            <div className="malls-table-row-content button-row">
                <div className="button-item outline" onClick={deleteClick}>
                    Remover
                </div>
                <div className="button-item" onClick={editClick}>
                    Editar
                </div>
                <div className="malls-listing__config-click" onClick={configClick}>
                    <FontAwesomeIcon icon="cog" />
                </div>
            </div>
        </div>
    )
}

export default MallsListingTableRow
