import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Formik, FormikHelpers } from 'formik'
import { stringify } from 'query-string'
import { mutate } from 'swr'
import * as Yup from 'yup'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList } from 'types'
import { ProductPart, ProductFormFields, Product } from 'types/marketplace'

import { EditProductModalBackground, EditProductModalContainer } from './edit-product-modal.styles'
import ProductFormContent from './product-form-content'

interface Props {
    isActive: boolean
    closeClick(): void
    products: Product[] | undefined
    updateProductStatus(productId: number, status: number): void
}

const EditProductModal: React.FC<Props> = ({ isActive, closeClick, products, updateProductStatus }) => {
    const { store } = useAuth()
    const { setErrorModal, setLoading, setSnackbar } = useUI()
    const history = useHistory()
    const { pathname } = history.location

    const [isEffectActive, setIsEffectActive] = useState(false)
    const [isDisplayed, setIsDisplayed] = useState(false)

    const editingMatch = pathname.match(new RegExp(/editar-produto\/\d+/g))

    const productInitialValues = {
        id: '',
        code_pdv: '',
        name: '',
        description: '',
        manage_stock: 0,
        stock: 0,
        category_id: '',
        is_featured: undefined as unknown as 0 | 1,
        is_offer: undefined as unknown as 0 | 1,
        price: '',
        price_offer: '',
        tags: [] as number[],
        shifts: [] as number[],
        parts: [] as ProductPart[],
        status: 1,
    } as ProductFormFields

    const productValidationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Nome do produto é obrigatório'),
        description: Yup.string().trim(),
        category_id: Yup.string().required('Categoria do produto é obrigatória'),
        price: Yup.number().required('Preço é obrigatório'),
        is_offer: Yup.bool(),
        price_offer: Yup.number()
            .when('is_offer', {
                is: (is_offer: number) => is_offer,
                then: Yup.number().required('Oferta de preço é obrigatória quando Em oferta é "Sim"'),
            })
            .when('price', (price: number, schema: Yup.NumberSchema) =>
                schema.max(price, 'Oferta de preço deve ser menor que o Preço')
            ),
        manage_stock: Yup.bool(),
        stock: Yup.number().when('manage_stock', {
            is: (manage_stock: number) => manage_stock,
            then: Yup.number().required('Em estoque é obrigatório quando Estoque gerenciável é "Sim"'),
        }),
    })

    async function onSubmitProduct(values: ProductFormFields, { resetForm }: FormikHelpers<ProductFormFields>) {
        try {
            setLoading(true)

            const { data } = await api.request({
                method: values.id ? 'put' : 'post',
                url: values.id ? `/painel/product/${values.id}` : '/painel/product',
                data: {
                    ...values,
                    id: undefined,
                    category: undefined,
                    photo: undefined,
                    parts: values.id ? undefined : values.parts,
                    price: Number(values.price).toFixed(2),
                    price_offer: Number(values.price_offer).toFixed(2),
                    store_id: store.id,
                },
            })

            if (values.photo instanceof File) {
                const formData = new FormData()
                formData.append('image', values.photo as File)

                await api.post(`/painel/product/${values?.id || data?.id}/image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
            }

            setSnackbar({ message: editingMatch ? 'Produto atualizado' : 'Produto criado' })
            closeClick()
            resetForm()

            if (values.id && (values.photo instanceof File || !values.photo)) {
                setTimeout(() => {
                    window.location.reload()
                }, 300)
                return
            }

            if (!products) return

            const params = { store_id: store.id, order_by: 'name' }
            mutate(`/painel/products?${stringify(params)}`, {
                ...products,
                items: editingMatch
                    ? products.map(product => (product.id === data.id ? data : product))
                    : [...products, data],
            })
        } catch (error) {
            setErrorModal({
                title: editingMatch ? 'Erro ao editar produto' : 'Erro ao criar produto',
                subtitle: showErrors(error),
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isActive) {
            setIsDisplayed(true)
            setTimeout(() => {
                setIsEffectActive(true)
            }, 100)
        } else {
            setIsEffectActive(false)
            setTimeout(() => {
                setIsDisplayed(false)
            }, 100)
        }
    }, [isActive])

    return (
        <EditProductModalContainer isDisplayed={isDisplayed}>
            <Formik
                initialValues={productInitialValues}
                validationSchema={productValidationSchema}
                onSubmit={(values, formikHelpers) => onSubmitProduct(values, formikHelpers)}
            >
                <ProductFormContent
                    closeClick={closeClick}
                    isEffectActive={isEffectActive}
                    products={products}
                    updateProductStatus={updateProductStatus}
                />
            </Formik>
            <EditProductModalBackground isEffectActive={isEffectActive} onClick={closeClick} />
        </EditProductModalContainer>
    )
}

export default EditProductModal
