import { useCallback } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { getFormInputError, getFormattedDateFilters } from 'helpers'
import { useDataFetching } from 'hooks'
import api from 'services/api'
import { Mall, PagedList, IFormDebitNotes, Store } from 'types'
import { testCNPJ } from 'utils/validators'

import {
    ButtonsContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    Row,
    MiddleContainer,
    PeriodButton,
    PrimaryButton,
    ContainerForm,
    Title,
} from './debit-notes-header.styles'

interface Props {
    title?: string
    filters?: IFormDebitNotes
    onFilterData?(filters: IFormDebitNotes): void
    disabled: boolean
}

const DebitNotesHeader: React.FC<Props> = ({ title, filters, onFilterData, disabled }) => {
    const { errors, getFieldProps, handleSubmit, touched, setFieldValue, setValues, values } = useFormik({
        initialValues: {
            download: 'xlsx',
            start_date: '',
            end_date: '',
            mall_id: '',
            store_id: '',
            number: '',
            service: '',
            percent: '',
            supplier_social_reason: 'LOGAROO CONSULTORIA EM PUBLICIDADE LTDA',
            supplier_cnpj: '36.446.304/0001-24',
            supplier_address: 'Rua Osvaldo Cruz, 2133, Sala 102, Dionísio Torres, CEP: 60125-151, Fortaleza - CE',
            payer_social_reason: '',
            payer_cnpj: '',
            payer_address: '',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
            mall_id: Yup.string().required('Selecione o Mall'),
            store_id: Yup.string().required('Selecione uma loja'),
            number: Yup.string().required('Número da nota é obrigatório'),
            service: Yup.string().required('Serviço Referente é obrigatório'),
            percent: Yup.number()
                .min(0, 'percentual deve ter entre 0 e 100')
                .max(100, 'percentual deve ter entre 0 e 100')
                .required('Percentual da nota é obrigatório'),
            supplier_social_reason: Yup.string().required('Razão Social do fornecedor é obrigatório'),
            supplier_cnpj: Yup.string()
                .required('CNPJ do fornecedor é obrigatório')
                .test('cnpj-invalid', 'CNPJ inválido', value => testCNPJ(value)),
            supplier_address: Yup.string().required('Endereço do fornecedor é obrigatório'),
            payer_social_reason: Yup.string().required('Razão Social do pagador é obrigatório'),
            payer_cnpj: Yup.string()
                .required('CNPJ do pagador é obrigatório')
                .test('cnpj-invalid', 'CNPJ inválido', value => testCNPJ(value)),
            payer_address: Yup.string().required('Endereço do pagador é obrigatório'),
        }),
        onSubmit: async values => {
            if (onFilterData) {
                const store = stores.items.find(item => item.id === Number(values.store_id))
                onFilterData({
                    ...values,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                    supplier_cnpj: values.supplier_cnpj.replace(/\D+/g, ''),
                    payer_cnpj: values.payer_cnpj.replace(/\D+/g, ''),
                    store_name: store.slug,
                })
            }
        },
    })

    const { data: malls, loading: loadingMalls } = useDataFetching<PagedList<Mall>>('/painel/malls', {
        params: { order_by: 'name', status: [1], per_page: 100 },
    })

    const { data: stores, loading: loadingStores } = useDataFetching<PagedList<Store>>(
        values.mall_id ? '/painel/stores-to-select' : null,
        {
            params: { mall_id: values.mall_id },
        }
    )

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    const _getAddressByStore = useCallback(
        async store_id => {
            try {
                const { data } = await api.get(`/painel/store/${store_id}`)
                setFieldValue('payer_social_reason', data.company_name)
                setFieldValue('payer_cnpj', data.cnpj)
                const address = `${data.street}, ${data.number}, ${(data.complement && data.complement + ', ') || ''} ${
                    data.neighborhood.name
                }, CEP: ${data.zipcode}, ${data.neighborhood.city.name} - ${data.neighborhood.city.state.uf}`
                setFieldValue('payer_address', address)
            } catch (error) {
                console.log('error', error)
            }
        },
        [setFieldValue]
    )

    return (
        <>
            <ModalLoading visible={loadingMalls || loadingStores} />
            <HeaderContainer>
                <HeaderTitle>Selecione a loja e período</HeaderTitle>
                <MiddleContainer>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Mall"
                                type="select"
                                options={malls?.items.map(mall => ({
                                    label: mall.name,
                                    value: mall.id,
                                }))}
                                inputProps={{
                                    ...getFieldProps('mall_id'),
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('mall_id', value)
                                        setFieldValue('store_id', '')
                                    },
                                }}
                                errorMessage={getFormInputError('mall_id', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Loja"
                                type="select"
                                options={stores?.items.map(store => ({
                                    label: store.name,
                                    value: store.id,
                                }))}
                                inputProps={{
                                    ...getFieldProps('store_id'),
                                    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('store_id', value)
                                        _getAddressByStore(value)
                                    },
                                }}
                                errorMessage={getFormInputError('store_id', errors, touched)}
                            />
                        </InputContainer>
                    </InputsRow>
                    <InputsRow>
                        <InputContainer>
                            <InputItem
                                labelText="Data inicial"
                                type="date"
                                inputProps={getFieldProps('start_date')}
                                errorMessage={getFormInputError('start_date', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Data final"
                                type="date"
                                inputProps={getFieldProps('end_date')}
                                errorMessage={getFormInputError('end_date', errors, touched)}
                            />
                        </InputContainer>
                    </InputsRow>
                    <ButtonsContainer>
                        {periodFilters.map((filter, filterIndex) => (
                            <PeriodButton key={filterIndex} onClick={filter.onClick}>
                                {filter.label}
                            </PeriodButton>
                        ))}
                    </ButtonsContainer>
                </MiddleContainer>
                <Row>
                    <ContainerForm>
                        <hr />
                    </ContainerForm>
                    <ContainerForm />
                </Row>

                <Row>
                    <ContainerForm>
                        <Title>Informações da Nota Fiscal</Title>
                        <Row>
                            <ContainerForm>
                                <InputItem
                                    name="number-note"
                                    labelText="Número da Nota de Débito"
                                    inputProps={getFieldProps('number')}
                                    errorMessage={getFormInputError('number', errors, touched)}
                                />
                            </ContainerForm>
                            <ContainerForm>
                                <InputItem
                                    name="service-name"
                                    labelText="Serviço Referente"
                                    inputProps={getFieldProps('service')}
                                    errorMessage={getFormInputError('service', errors, touched)}
                                />
                            </ContainerForm>
                            <ContainerForm>
                                <InputItem
                                    name="percent-name"
                                    labelText="Percentual da Nota de Débito"
                                    type="number"
                                    min={1}
                                    max={100}
                                    inputProps={{
                                        ...getFieldProps('percent'),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('percent', Number(value))
                                        },
                                    }}
                                    errorMessage={getFormInputError('percent', errors, touched)}
                                />
                            </ContainerForm>
                        </Row>
                        <Title>Informações do Fornecedor</Title>
                        <Row>
                            <ContainerForm>
                                <InputItem
                                    labelText="Razão Social do Fornecedor"
                                    disabled
                                    inputProps={getFieldProps('supplier_social_reason')}
                                    errorMessage={getFormInputError('supplier_social_reason', errors, touched)}
                                />
                            </ContainerForm>
                            <ContainerForm>
                                <InputItem
                                    labelText="CNPJ do Fornecedor"
                                    disabled
                                    inputProps={{ ...getFieldProps('supplier_cnpj'), mask: '99.999.999/9999-99' }}
                                    errorMessage={getFormInputError('supplier_cnpj', errors, touched)}
                                />
                            </ContainerForm>
                        </Row>
                        <InputItem
                            labelText="Endereço do Fornecedor"
                            disabled
                            inputProps={getFieldProps('supplier_address')}
                            errorMessage={getFormInputError('supplier_address', errors, touched)}
                        />

                        <Title>Informações do Pagador</Title>
                        <Row>
                            <ContainerForm>
                                <InputItem
                                    labelText="Razão Social do Pagador"
                                    disabled
                                    inputProps={getFieldProps('payer_social_reason')}
                                    errorMessage={getFormInputError('payer_social_reason', errors, touched)}
                                />
                            </ContainerForm>
                            <ContainerForm>
                                <InputItem
                                    labelText="CNPJ do Pagador"
                                    disabled
                                    inputProps={{ ...getFieldProps('payer_cnpj'), mask: '99.999.999/9999-99' }}
                                    errorMessage={getFormInputError('payer_cnpj', errors, touched)}
                                />
                            </ContainerForm>
                        </Row>
                        <InputItem
                            labelText="Endereço do Pagador"
                            disabled
                            inputProps={getFieldProps('payer_address')}
                            errorMessage={getFormInputError('payer_address', errors, touched)}
                        />
                        <PrimaryButton disabled={disabled} onClick={() => handleSubmit()}>
                            Gerar Nota
                        </PrimaryButton>
                    </ContainerForm>
                    <ContainerForm />
                </Row>
            </HeaderContainer>
        </>
    )
}

export default DebitNotesHeader
