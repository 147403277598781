import { AxiosError } from 'axios'
import { useFormikContext } from 'formik'
import { stringify } from 'query-string'
import { mutate } from 'swr'

import { ChipsInput, InputItem, LoadingMessage } from 'components/_common'
import Checkbox from 'components/_common/checkbox/checkbox'

import { useUI } from 'contexts'
import { getFormInputError, showErrors } from 'helpers'
import { useAuth, useDataFetching, useImagePreview } from 'hooks'
import api from 'services/api'
import { PagedList, Shift } from 'types'
import { Product, ProductCategory, ProductFormFields, Tag } from 'types/marketplace'

import {
    DetailsContainer,
    ImageColumn,
    ImageContainer,
    ImageTitle,
    TopContainer,
    ImageSide,
    SizesContainer,
    SizeItem,
    ButtonsSide,
    ButtonItem,
    InputsColumn,
    MidContainer,
    CheckboxesContainer,
    CheckboxesTitle,
    CheckboxesRow,
    MidInput,
    InputRow,
    InputColumn,
    TurnsContainer,
    BottomContainer,
    TagsContainer,
    DeleteButton,
    DeleteIcon,
    EmptyPhoto,
    InputLabel,
    MidLeftSide,
} from './product-details-content.styles'

interface Props {
    closeClick(): void
    productId: number | undefined
    products: Product[] | undefined
    loading: boolean
}

const ProductDetailsContent: React.FC<Props> = ({ closeClick, productId, products, loading }) => {
    const { setConfirmationModal, setErrorModal, setLoading, setSnackbar } = useUI()
    const { store, mall } = useAuth()

    const { errors, getFieldProps, setFieldValue, touched, values } = useFormikContext<ProductFormFields>()

    const { image } = useImagePreview(values.photo)

    function handleRequestError(error: AxiosError) {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })
    }

    function onDeleteProduct() {
        async function handleDeleteProduct() {
            try {
                setLoading(true)

                await api.delete(`/painel/product/${productId}`)

                setSnackbar({ message: 'Produto excluído' })

                const params = { store_id: store.id, order_by: 'name' }
                mutate(`/painel/products?${stringify(params)}`, {
                    ...products,
                    items: products?.filter(product => product.id !== productId),
                })

                closeClick()
            } catch (error) {
                handleRequestError(error)
            } finally {
                setLoading(false)
            }
        }

        setConfirmationModal({
            title: 'Excluir produto',
            subtitle: 'Deseja mesmo excluir este produto',
            type: 'delete',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, excluir',
            rightButtonClick: handleDeleteProduct,
        })
    }

    function onDeleteImage() {
        async function handleDeleteImage() {
            try {
                setLoading(true)

                if (values?.id) await api.delete(`/painel/product/${values?.id}/image`)

                setFieldValue('photo', '')
                setSnackbar({ message: 'Imagem removida' })
            } catch (error) {
                handleRequestError(error)
            } finally {
                setLoading(false)
            }
        }

        setConfirmationModal({
            title: 'Remover imagem',
            subtitle: 'Deseja mesmo remover a imagem deste produto',
            leftButtonText: 'Fechar',
            rightButtonText: 'Sim, remover',
            rightButtonClick: handleDeleteImage,
        })
    }

    async function onChangeImage(file: File) {
        if (!file) return

        setFieldValue('photo', file)
    }

    function onChangeTag(tagId: number) {
        if (values.tags.some(id => id === tagId)) {
            setFieldValue(
                'tags',
                values.tags.filter(id => id !== tagId)
            )
        } else {
            setFieldValue('tags', [...values.tags, tagId])
        }
    }

    function onChangeShift(shiftId: number) {
        if (values.shifts.some(id => id === shiftId)) {
            setFieldValue(
                'shifts',
                values.shifts.filter(id => id !== shiftId)
            )
        } else {
            setFieldValue('shifts', [...values.shifts, shiftId])
        }
    }

    const { data: productCategories } = useDataFetching<PagedList<ProductCategory>>('/painel/product-categories', {
        params: { mall_id: mall.id, per_page: -1, order_by: 'name' },
    })

    const { data: tags } = useDataFetching<PagedList<Tag>>('/painel/tags')
    //const { data: shifts } = useDataFetching<PagedList<Shift>>(`/painel/mall/${mall.id}/shifts`)

    const hasPhoto = image.length > 0

    if (loading) return <LoadingMessage />

    return (
        <DetailsContainer>
            <TopContainer>
                <ImageColumn>
                    <ImageSide>
                        <ImageTitle>Imagem do Produto</ImageTitle>
                        {hasPhoto ? <ImageContainer src={image} /> : <EmptyPhoto />}
                        <SizesContainer>
                            <SizeItem>
                                Tamanho Máximo: <b>800KB</b>
                            </SizeItem>
                            <SizeItem>
                                Tamanho Máximo: <b>1920x1080</b>
                            </SizeItem>
                            <SizeItem>
                                Formatos: <b>JPG, JPEG</b>
                            </SizeItem>
                        </SizesContainer>
                    </ImageSide>
                    <ButtonsSide>
                        <ButtonItem>
                            <label htmlFor="photo">{hasPhoto ? 'Alterar' : 'Adicionar'}</label>
                            <input
                                type="file"
                                name="photo"
                                id="photo"
                                onChange={({ target: { files } }) => files && onChangeImage(files[0])}
                            />
                        </ButtonItem>
                        {hasPhoto && <ButtonItem onClick={onDeleteImage}>Remover</ButtonItem>}
                    </ButtonsSide>
                </ImageColumn>
                <InputsColumn>
                    <InputItem
                        labelText="Categoria"
                        type="select"
                        options={productCategories?.items.map(category => ({
                            label: category.name,
                            value: category.id,
                        }))}
                        inputProps={{ ...getFieldProps('category_id') }}
                        errorMessage={getFormInputError('category_id', errors, touched)}
                    />
                    <InputItem
                        labelText="Nome do Produto"
                        inputProps={{ ...getFieldProps('name') }}
                        errorMessage={getFormInputError('name', errors, touched)}
                    />
                    <InputItem
                        labelText="Descrição"
                        type="textarea"
                        inputProps={{ ...getFieldProps('description') }}
                        errorMessage={getFormInputError('description', errors, touched)}
                    />
                    <InputRow>
                        <InputColumn>
                            <InputItem
                                labelText="SKU"
                                inputProps={{ ...getFieldProps('code_pdv') }}
                                errorMessage={getFormInputError('code_pdv', errors, touched)}
                            />
                        </InputColumn>
                        <InputColumn>
                            <InputItem
                                labelText="Serve quantas pessoas?"
                                type="number"
                                inputProps={{ ...getFieldProps('ideal_for'), min: 0 }}
                                errorMessage={getFormInputError('ideal_for', errors, touched)}
                            />
                        </InputColumn>
                    </InputRow>
                    <InputRow>
                        <InputColumn>
                            <InputItem
                                labelText="Preço"
                                type="currency"
                                inputProps={{ ...getFieldProps('price') }}
                                errorMessage={getFormInputError('price', errors, touched)}
                            />
                        </InputColumn>
                        <InputColumn>
                            <InputLabel>
                                <span>Valor promocional</span>
                                <Checkbox
                                    isSquared
                                    inputProps={{
                                        ...getFieldProps('is_offer'),
                                        onChange: () => {
                                            setFieldValue('is_offer', values.is_offer ? 0 : 1)
                                        },
                                        value: values.is_offer ? 1 : 0,
                                    }}
                                />
                            </InputLabel>
                            <InputItem
                                type="currency"
                                inputProps={{ ...getFieldProps('price_offer'), disabled: !values.is_offer }}
                                errorMessage={getFormInputError('price_offer', errors, touched)}
                            />
                        </InputColumn>
                    </InputRow>
                    <InputRow>
                        <InputColumn>
                            <InputItem
                                labelText="A partir de"
                                type="currency"
                                inputProps={{ ...getFieldProps('starts_at') }}
                                errorMessage={getFormInputError('starts_at', errors, touched)}
                            />
                        </InputColumn>
                    </InputRow>
                    <InputRow>
                        <InputLabel>
                            <Checkbox
                                isSquared
                                inputProps={{
                                    ...getFieldProps('is_order_token'),
                                    onChange: () => {
                                        setFieldValue('is_order_token', values.is_order_token ? 0 : 1)
                                    },
                                    value: values.is_order_token ? 1 : 0,
                                }}
                            />
                            <span>Usar ficha p/ retirada em balcão</span>
                        </InputLabel>
                    </InputRow>
                </InputsColumn>
            </TopContainer>

            <TagsContainer>
                <ChipsInput
                    label="Tags"
                    items={tags?.items?.map(tag => ({ label: tag.name, value: tag.id }))}
                    selectedItems={values.tags}
                    onClickItem={tagId => onChangeTag(tagId as number)}
                />
            </TagsContainer>

            {productId && (
                <DeleteButton onClick={onDeleteProduct}>
                    <DeleteIcon /> Excluir produto
                </DeleteButton>
            )}
        </DetailsContainer>
    )
}

export default ProductDetailsContent
