import { useState, useEffect, useRef, useCallback } from 'react'

import ButtonLoadMore from 'components/button-load-more/button-load-more'

import EmptyMessage from '../../components/empty-message/empty-message'
import ModalConfirmation from '../../components/modal-confirmation'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { showErrors } from '../../helpers'
import api from '../../services/api'

import MallsListingTableHeader from './components/malls-listing-table-header/malls-listing-table-header'
import MallsListingTableRow from './components/malls-listing-table-row/malls-listing-table-row'
import './style.scss'

const MallsListing = ({ history }) => {
    const modalMessage = useRef(null)
    const modalDelete = useRef(null)

    const [loading, setLoading] = useState(false)
    const [malls, setMalls] = useState({ items: [] })
    const [hasMore, setHasMore] = useState(false)
    const [modalMessageData, setModalMessageData] = useState({ title: '', message: '', onClose: () => {} })
    const [deleteID, setDeleteID] = useState(null)

    const getMalls = useCallback(
        async ({ current_page = 1, malls = { items: [] } } = {}) => {
            try {
                setLoading(true)

                const { data } = await api.get('/painel/malls', {
                    params: { order_by: 'name', current_page },
                })

                setHasMore(data?.has_more || false)

                setMalls({
                    ...data,
                    items: current_page > 1 ? [...malls.items, ...data.items] : data.items,
                })
            } catch (error) {
                setModalMessageData({
                    title: 'Erro',
                    message: 'Não foi possível carregar os dados.',
                    onClose: () => history.push('/gestao-mall'),
                })

                modalMessage.current.openModal()
            } finally {
                setLoading(false)
            }
        },
        [history]
    )

    useEffect(() => {
        getMalls()
    }, [getMalls])

    const deleteMall = async () => {
        try {
            setLoading(true)

            await api.delete(`/painel/mall/${deleteID}`)

            getMalls()
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
            })

            modalMessage.current.openModal()
        } finally {
            setLoading(false)
        }
    }

    const renderEmptyMessage = () => {
        return <EmptyMessage icon="dollar-sign">Não foram encontrados malls cadastrados cadastradas.</EmptyMessage>
    }

    return (
        <div className="malls-listing-container">
            <ModalLoading visible={loading} />

            <ModalMessage
                ref={modalMessage}
                title={modalMessageData.title}
                message={modalMessageData.message}
                onClose={modalMessageData.onClose}
            />

            <ModalConfirmation
                ref={modalDelete}
                title="Excluir Mall"
                message="Deseja realmente excluir este mall?"
                onYes={() => deleteMall()}
            />

            <TopRowDefault
                title="Malls"
                buttons={[
                    {
                        title: 'Criar mall',
                        onClick: () => history.push('/painel-malls/criar-mall'),
                    },
                ]}
            />

            <MallsListingTableHeader />

            {malls.items.map((item, index) => (
                <MallsListingTableRow
                    key={index}
                    id={item.id}
                    name={item.name}
                    address={item.address}
                    status={item.status ? 'Ativo' : 'Inativo'}
                    editClick={() => history.push(`/painel-malls/editar-mall/${item.id}`)}
                    configClick={() => history.push(`${item.slug}/gestao-mall`)}
                    deleteClick={() => {
                        setDeleteID(item.id)

                        modalDelete.current.openModal()
                    }}
                />
            ))}

            {!malls.items.length && !loading && renderEmptyMessage()}

            <ButtonLoadMore
                loading={loading}
                visible={hasMore}
                onClick={() => getMalls({ current_page: ++malls.current_page, malls })}
            />
        </div>
    )
}

export default MallsListing
