/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { format } from 'date-fns'
import useSound from 'use-sound'

import { useAuth, useReduxAction } from 'hooks'
import { usePrinter } from 'hooks/printer/usePrinter'
import { useEchoConnection } from 'hooks/use-echo-connection'
import api from 'services/api'
import {
    AddOrderAction,
    OrdersInProgressActions,
    OrdersInProgressTypes,
    OrdersListKey,
    OrdersScoreboard,
    UpdateOrderAction,
} from 'store/reducers/ordersInProgress'
import RootState, { OrdersInProgressState } from 'store/reducers/types'
import { Order } from 'types'

const bicycleBell = '/bicycle-bell.mp3'
const swissBell = '/swiss-bell.mp3'

type OrdersContextType = {
    isSocketConnected: boolean
    ordersInProgress: OrdersInProgressState
    refresh: () => Promise<void>
}

const InitialValue: OrdersContextType = {
    isSocketConnected: false,
    ordersInProgress: {
        highlightedOrderId: undefined,
        webSocketConnection: 'disconnected',
        scoreboard: {
            canceled: 0,
            collected: 0,
            delivered: 0,
            failed: 0,
            finished: 0,
            in_client: 0,
            in_moderation: 0,
            in_production: 0,
            new: 0,
            no_delivery: 0,
            running: 0,
            scheduled: 0,
            to_collect: 0,
        },
        routeOrders: [],
        orders: {
            scheduled: { loading: false, totals: 0, items: [] },
            inModeration: { loading: false, totals: 0, items: [] },
            inProduction: { loading: false, totals: 0, items: [] },
            toCollect: { loading: false, totals: 0, items: [] },
            awaitingCollect: { loading: false, totals: 0, items: [] },
            inRoute: { loading: false, totals: 0, items: [] },
            delivered: { loading: false, totals: 0, items: [] },
        },
    },
    refresh: async () => {},
}

const OrdersContext = createContext<OrdersContextType>(InitialValue)

interface Params {
    mall_id: number
    status: number[] | string[]
    merchant_id?: number
    per_page?: number
    has_route?: number
    start_date?: string
    end_date?: string
    with_takeout?: boolean
}

const OrdersContextProvider: React.FC = memo(({ children }) => {
    const timeoutRef = useRef<NodeJS.Timeout>()
    const isTakeoutRef = useRef<boolean>(false)
    const oldParamsRef = useRef<{ mall_id?: number; store_id?: number }>({})

    const dispatch = useDispatch()
    const { mall, store } = useAuth()
    const { hasConnectionPrinterApp, convertToPrintOrder, print } = usePrinter()
    const ordersInProgress = useSelector<RootState, OrdersInProgressState>(({ ordersInProgress }) => ordersInProgress)

    const [playBicycleBell] = useSound(bicycleBell)
    const [playSwissBell] = useSound(swissBell)

    const {
        addOrder,
        removeOrder,
        setHighlightedOrderId,
        setScoreboard,
        setListLoading,
        setOrders,
        updateOrder,
        clearOrder,
    } = OrdersInProgressActions

    const _onOrderChange = useCallback(
        (order: Order) => {
            if (Number(order?.status) === 3) playSwissBell()

            if (Number(order?.status) === 4) playBicycleBell()

            dispatch(setHighlightedOrderId(order.id))

            setTimeout(() => {
                dispatch(setHighlightedOrderId(undefined))
            }, 2000)
        },
        [dispatch, playSwissBell, playBicycleBell, setHighlightedOrderId]
    )

    useReduxAction<AddOrderAction>(action => _onOrderChange(action?.order), OrdersInProgressTypes.ADD_ORDER)
    useReduxAction<UpdateOrderAction>(action => _onOrderChange(action?.order), OrdersInProgressTypes.UPDATE_ORDER)

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.new',
                callback: order => {
                    if (store?.configs?.print?.production && hasConnectionPrinterApp) {
                        const { auto_print, sales_channels } = store.configs.print.production
                        if (auto_print && sales_channels?.includes(order.sales_channel.id)) {
                            print('production', convertToPrintOrder(order))
                        }
                    }
                    dispatch(addOrder(order as Order))
                },
            },
            {
                name: '.updated',
                callback: o => {
                    const order = o as Order
                    if (order?.status === '0') {
                        dispatch(removeOrder(order))
                    } else {
                        if (order.status === '4' && !order.routes) {
                            if (store?.configs?.print?.dispatch && hasConnectionPrinterApp) {
                                const { auto_print, sales_channels, with_checklist } = store.configs.print.dispatch
                                if (auto_print && sales_channels?.includes(order.sales_channel?.id)) {
                                    print('dispatch', convertToPrintOrder(order), { with_checklist })
                                }
                            }
                        }
                        dispatch(updateOrder(order))
                    }
                },
            },
            {
                name: '.deleted',
                callback: order => dispatch(removeOrder(order as Order)),
            },
            {
                name: '.to_takeout',
                callback: order => {
                    if (!isTakeoutRef.current) {
                        dispatch(removeOrder(order as Order))
                    }
                },
            },
            {
                name: '.in_route',
                callback: order =>
                    store.id ? dispatch(updateOrder(order as Order)) : dispatch(removeOrder(order as Order)),
            },
            { name: '.out_route', callback: order => dispatch(addOrder(order as Order)) },
            { name: '.scoreboard', callback: scoreboard => dispatch(setScoreboard(scoreboard as OrdersScoreboard)) },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.configs?.print, hasConnectionPrinterApp])

    const _fetchOrders = useCallback(
        async (list: OrdersListKey, params: Params) => {
            dispatch(setListLoading(list, true))

            try {
                const { data } = await api.get(`/painel/dm-orders`, {
                    params: { ...params, with_takeout: isTakeoutRef.current ? 1 : 0 },
                })
                dispatch(setOrders(list, data))
            } catch (error) {
                console.log('error')
            }
            dispatch(setListLoading(list, false))
        },
        [dispatch, setListLoading, setOrders]
    )

    const _getOrders = useCallback(
        async (mallId: number, storeId?: number) => {
            const defaultParams = {
                mall_id: mallId,
                merchant_id: storeId,
            }

            _fetchOrders('scheduled', {
                ...defaultParams,
                status: [1],
                per_page: -1,
            })
            _fetchOrders('inModeration', {
                ...defaultParams,
                status: [2],
            })
            _fetchOrders('inProduction', {
                ...defaultParams,
                status: [3],
            })
            _fetchOrders('toCollect', {
                ...defaultParams,
                has_route: 0,
                status: [4],
            })
            _fetchOrders('awaitingCollect', {
                ...defaultParams,
                has_route: 1,
                status: [4],
            })
            _fetchOrders('inRoute', {
                ...defaultParams,
                has_route: 1,
                status: ['5', '6', '7', '10', '10A', '10B', '10C', '10D', '10E', '10F', '10G', '10H'],
            })
            _fetchOrders('delivered', {
                ...defaultParams,
                status: [8, 9, 11],
                start_date: format(new Date(), 'YYYY-MM-DD'),
                end_date: format(new Date(), 'YYYY-MM-DD'),
            })
        },
        [_fetchOrders]
    )

    const _getScoreboard = useCallback(
        async (mallId: number, storeId?: number) => {
            try {
                const { data } = await api.get(`/painel/dm-orders/scoreboard`, {
                    params: {
                        mall: mallId,
                        store: storeId,
                        today: true,
                    },
                })
                dispatch(setScoreboard(data as OrdersScoreboard))
            } catch (error) {
                console.log('error')
            }
        },
        [dispatch, setScoreboard]
    )

    const _onRestart = useCallback(() => {
        const { mall_id, store_id } = oldParamsRef.current
        if (mall_id) {
            _getScoreboard(mall_id, store_id)
            _getOrders(mall_id, store_id)
        }
    }, [])

    const { connected } = useEchoConnection<Order, Order>({
        enable: !!(store?.id || mall?.id),
        channelName: store?.id ? `orders.store.${store.id}` : mall.id ? `orders.mall.${mall.id}` : null,
        events: socketEvents,
        onRestart: _onRestart,
    })

    const _refresh = useCallback(async () => {
        if (mall?.id) {
            dispatch(
                setScoreboard({
                    canceled: 0,
                    collected: 0,
                    delivered: 0,
                    failed: 0,
                    finished: 0,
                    in_client: 0,
                    in_moderation: 0,
                    in_production: 0,
                    new: 0,
                    no_delivery: 0,
                    running: 0,
                    scheduled: 0,
                    to_collect: 0,
                })
            )
            _getScoreboard(mall.id, store?.id)
            _getOrders(mall.id, store?.id)
        }
    }, [mall, store, dispatch, setScoreboard, _getScoreboard, _getOrders])

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        isTakeoutRef.current = !!store?.id

        timeoutRef.current = setTimeout(() => {
            const old = oldParamsRef.current
            if (mall?.id && (old.mall_id !== mall.id || old.store_id !== store?.id)) {
                oldParamsRef.current = { mall_id: mall.id, store_id: store?.id }
                dispatch(
                    setScoreboard({
                        canceled: 0,
                        collected: 0,
                        delivered: 0,
                        failed: 0,
                        finished: 0,
                        in_client: 0,
                        in_moderation: 0,
                        in_production: 0,
                        new: 0,
                        no_delivery: 0,
                        running: 0,
                        scheduled: 0,
                        to_collect: 0,
                    })
                )
                _getScoreboard(mall.id, store?.id)
                _getOrders(mall.id, store?.id)
            }
        }, 1000)

        return () => {
            clearOrder()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mall, store])

    return (
        <OrdersContext.Provider value={{ ordersInProgress, refresh: _refresh, isSocketConnected: connected }}>
            {children}
        </OrdersContext.Provider>
    )
})

export { OrdersContext, OrdersContextProvider }
